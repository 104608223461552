import useCustomForm from "../../../../services/useCustomForm";
import {ClienteAnexoForm, ClienteProdutoForm} from "../../../cadastros/cliente";
import React, {useEffect, useState} from "react";
import Form, {FormApi} from "../../../../components/form";
import useCurrentAction from "../../../../services/useCurrentAction";
import {CCard, CCardBody, CCardHeader, CFormGroup, CInput, CLabel} from "@coreui/react";
import {NumericFormat} from "react-number-format";
import {getApi} from "../../../../apis/backendApis";
import financeiro, {SituacaoFaturaList, SituacaoFaturaTypes} from "../../index";
import {useLocation} from "react-router-dom";
import {formatter} from "../../../../components/numberBRLFormatForGrid";


const FaturaForm = () => {

    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();
    const [financeiros, setFinanceiros] = useState([]);
    let location = useLocation();
    const isView = () => {
        return action === 'view';
    };
    const initialValues = {
        id: '',
        ano: '',
        mes: '',
        cliente: [],
        situacao: '',
        valor: '',
        observacao: '',
        dataHoraGeracao: '',
        dataHoraVencimento: '',
        valorPago: ''
    }

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
    } = useCustomForm(
        {
            initialValues,
            route: 'fatura',
            onSubmit: values => {
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            }
        }
    );

    useEffect(() => {
        let idLocation = location.pathname.split('/');
        let idFatura = idLocation[idLocation.length - 1];
        let api = getApi()
        api.get(`fatura/financeiros/` + idFatura).then((response) => {
            setFinanceiros(response.data);
        })
    }, []);


    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const moduloId = (index: number) => {
        return index % 2 === 0 ? '' : 'bg-light';
    }

    /*const verificaSituacao = (value: number) => {
        switch (value) {
            case SituacaoFaturaTypes.PAGO:
                return 'bg-success text-white'
            case SituacaoFaturaTypes.CANCELADO:
                return 'bg-danger text-white'
            case SituacaoFaturaTypes.FATURADO:
                return 'bg-info text-white'
            case SituacaoFaturaTypes.FECHADO:
                return 'bg-warning'
            case SituacaoFaturaTypes.ENVIADO:
                return 'bg-success text-white'
        }
    }*/

    return (
        <Form entity="Fatura" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel> Cliente </CLabel>
                <CInput
                    type="text"
                    id="cliente"
                    readOnly={isView()}
                    disabled={isView()}
                    value={values?.cliente?.pessoa?.nome || ''}
                />
            </CFormGroup>

            <CFormGroup>
                <CLabel> Situação </CLabel>
                <CInput
                    name="situacao"
                    value={SituacaoFaturaList.getDescription(values.situacao)}
                    readOnly={isView()}
                    onChange={handleChange}
                    disabled={isView()}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel> Data/Hora da Geração </CLabel>
                <CInput
                    readOnly={isView()}
                    disabled={isView()}
                    type="datetime-local"
                    id="dataHoraGeracao"
                    name="dataHoraInicio"
                    value={values.dataHoraGeracao}/>
            </CFormGroup>
            <CFormGroup>
                <CLabel> Valor Total da Fatura </CLabel>
                <NumericFormat
                    prefix={isView() ? 'R$ ' : ''}
                    name="valor"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={false}
                    customInput={CInput}
                    value={values.valor}
                    readOnly={isView()}
                    disabled={isView()}
                />
            </CFormGroup>
            <CFormGroup>
                <CLabel> Valor Total Pago </CLabel>
                <NumericFormat
                    prefix={isView() ? 'R$ ' : ''}
                    name="valor"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={false}
                    customInput={CInput}
                    value={values.valorPago}
                    readOnly={isView()}
                    disabled={isView()}
                />
            </CFormGroup>
            <CFormGroup className={'font-lg p-3 text-wrap'}>
                <div className={'row border-bottom border-top header font-weight-bold'}>
                    <div className={'col-3 border-right border-left p-1'}>
                        Objeto de Contrato
                    </div>
                    <div className={'col-6 border-right p-1'}>
                        Observação
                    </div>
                    <div className={'col text-wrap border-right p-1'}>
                        Valor de Cobrança
                    </div>
                </div>
                {financeiros.map((financeiro: any, index: number) => (
                    <div key={index}>
                        <div className={`row ${moduloId(index) || ''}`}>
                            <div className={'col-3 border-bottom border-right border-left'}>
                                {financeiro.objcon_nome}
                            </div>
                            <div className={'col-6 border-bottom border-right'}>
                                {financeiro.fin_observacao}
                            </div>
                            <div className={'col border-bottom border-right'}>
                                {formatter.format(financeiro.fin_valor_cobranca).toString()}
                            </div>
                        </div>
                    </div>
                ))}
            </CFormGroup>
        </Form>
    );
}
export default FaturaForm;
import {GridApi} from "ag-grid-community";
import {ActionProps} from "../../../../components/gridAction";
import Grid, {ListaMultiplaFilter} from "../../../../components/grid";
import {numberDecimalFormat} from "../../../../components/numberBRLFormatForGrid";

import {AgGridColumn} from "ag-grid-react";
import React from "react";
import {
    MesList,
    mesListFormater,
    SituacaoFaturaList,
    situacaoFaturaListFormater, SituacaoFaturaTypes,
} from "../../index";
import enumDescription from "../../../../services/enumDescription";
import {useLocation} from "react-router-dom";


const FaturaList = () => {

    const actions: Array<ActionProps> = [
        {
            route:'fechamento',
            color:'success',
            type:'normal',
            title:'Calcular Fatura'
        },
        {
            route:'fatura/enviar',
            color:'primary',
            type:'normal',
            title:'Enviar para E-commerce'
        },
        {
            route:'fatura/cancelar',
            color:'danger',
            type:'post',
            title:'Cancelar Fatura'

        },
        {
            route:'fatura/visualizar',
            color:'info',
            type:'grid',
            title:'Detalhes'
        },
        {
            route:'fatura/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        },
        {
            route:'/faturaDocumento/list',
            color:'primary',
            type:'grid',
            title:'Documentos'
        },
        {
            route:'/faturaDocumento/alterar',
            color:'info',
            type:'grid',
            title:'Liquidar Boletos',
            openInNewTab: true
        }

    ];

    const competenciaFiltro = (filtroCompetenciaValue: string | null) => {
        if(filtroCompetenciaValue !== null){
            let split = filtroCompetenciaValue.split('/');
            if(split.length == 2){
                let ano  = parseInt(split[0]);
                let mes  = parseInt(split[1]);
                if(ano > 0 && mes > 0){
                    return {
                        ano: ano,
                        mes: mes
                    }
                }
            }
        }
        return null;
    };

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const competenciaFilter = competenciaFiltro(useQuery()?.get('competencia'));
    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoFaturaTypes.SITUACAO_EM_PROCESSAMENTO, SituacaoFaturaTypes.FECHADO,SituacaoFaturaTypes.FATURADO, SituacaoFaturaTypes.ENVIADO,  SituacaoFaturaTypes.PAGO]
        });
        if(competenciaFilter !== null){
            const filterInstanceAno = gridApi.getFilterInstance('ano');
            const filterInstanceMes = gridApi.getFilterInstance('mes');
            filterInstanceAno?.setModel({
                type:  'equals',
                filter:  competenciaFilter.ano
            })
            filterInstanceMes?.setModel({
                type: 'in',
                value: [competenciaFilter.mes]
            })
        }
        gridApi.onFilterChanged();
    }

    return (
        <Grid actions={actions} rota="fatura/list" handleGridReady={handleGridReady} >
            <AgGridColumn width={100}  headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn width={150} sort="desc" headerName="Ano" field="ano"  sortable={ true } filter={ 'agNumberColumnFilter' } ></AgGridColumn>
            <AgGridColumn width={150} sort="desc" headerName="Mês" field="mes"  sortable={ true } valueFormatter={mesListFormater} filter={ FiltroMes } ></AgGridColumn>
            <AgGridColumn width={200} headerName="Situação" field="situacao"  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoFaturaListFormater}></AgGridColumn>
            <AgGridColumn flex={1} headerName="Cliente" field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} headerName="Valor" field="valor" filter={ 'agNumberColumnFilter'} valueFormatter={numberDecimalFormat} sortable={ true } ></AgGridColumn>
            <AgGridColumn flex={1} headerName="Valor Pago" field="valorPago" filter={ 'agNumberColumnFilter'} valueFormatter={numberDecimalFormat} sortable={ true } ></AgGridColumn>
        </Grid>
    );
}

class FiltroMes extends ListaMultiplaFilter{
    getList(): enumDescription {
        return MesList;
    }
}

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoFaturaList;
    }
}

export default FaturaList;